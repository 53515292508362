import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

interface RouteParams {
  id_enlace?: string;
  filename?: string;
  [key: string]: string | undefined;
}

const FileDisplay = () => {
  const { id_enlace, filename } = useParams<RouteParams>();
  const [fileUrl, setFileUrl] = useState<string>('');

  useEffect(() => {
    if (id_enlace && filename) {
      const filePath = `${API_URL}/files/${id_enlace}/${filename}`;
      setFileUrl(filePath);
    }
  }, [id_enlace, filename]);

  const isOfficeFile = (filename: string) => {
    const officeExtensions = ['.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx'];
    return officeExtensions.some((ext) => filename.toLowerCase().endsWith(ext));
  };

  const downloadFile = async () => {
    if (!fileUrl || !filename) return;

    try {
      const response = await fetch(fileUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      });

      if (!response.ok) throw new Error('Network response was not ok');

      const blob = await response.blob();

      // Handle Firefox download
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();

      // Cleanup
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  const renderFile = () => {
    if (!filename) return <p>No se encontró el archivo.</p>;

    if (filename.toLowerCase().endsWith('.pdf')) {
      return (
        <iframe
          src={fileUrl}
          width="100%"
          height="600px"
        />
      );
    } else if (filename.toLowerCase().endsWith('.jpg') || filename.toLowerCase().endsWith('.png')) {
      return <img src={fileUrl} alt={filename} style={{ maxWidth: '100%' }} />;
    } else if (isOfficeFile(filename)) {
      const viewerUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(fileUrl)}`;
      return (
        <iframe
          src={viewerUrl}
          width="100%"
          height="600px"
          title={filename}
          style={{ border: 'none' }}
        />
      );
    } else {
      return (
        <button onClick={downloadFile} style={{ padding: '10px 20px', cursor: 'pointer' }}>
          Click aquí para descargar el archivo
        </button>
      );
    }
  };

  return (
    <div>
      <h2>Archivo: {filename || 'No especificado'}</h2>
      {fileUrl ? renderFile() : <p>Cargando archivo...</p>}
    </div>
  );
};

export default FileDisplay;
